<template>
  <div>
    <validation-observer ref="simpleRules">
      <public />
      <request-detail />
      <expertise v-if="swapcar.detailForm === true" />
      <save-button :action-methods="submitForm" />
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import Public from '@/views/Swapcars/components/Public.vue'
import RequestDetail from '@/views/Swapcars/components/RequestDetail.vue'
import Expertise from '@/views/Swapcars/components/Expertise.vue'
import SaveButton from '@/views/Swapcars/forms/SaveButton.vue'

export default {
  name: 'Edit',
  components: {
    ValidationObserver,
    Public,
    RequestDetail,
    Expertise,
    SaveButton,
  },
  data() {
    return {
      submitStatus: false,
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
    saveStatus() {
      return this.$store.getters['swapcars/getSwapcarSaveStatus']
    },
  },
  watch: {
    swapcar(val) {
      if (val.id) {
        this.loadedData()
      }
    },
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          id_com_brand: null,
          id_com_location: null,
          sdate: null,
          edate: null,
          count: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.swapcar.submitStatus = false
    },
  },
  created() {
    this.getSwapcar()
    localize('tr')
  },
  methods: {
    getSwapcar() {
      this.$store.dispatch('swapcars/swapcarView', this.$route.params.id)
    },
    loadedData() {
      if (this.swapcar.id) {
        if (this.swapcar.id_com_swap_brand) {
          this.$store.dispatch('swapmodels/swapmodelsList', {
            select: ['com_swap_model.id AS id', 'com_swap_model.title AS title'],
            where: {
              id_com_swap_brand: this.swapcar.id_com_swap_brand,
            },
          })
        }
        if (this.swapcar.swapcar_type === '1') {
          this.swapcar.detailForm = false
        } else {
          this.swapcar.detailForm = true
        }
      }
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitStatus = true
          this.swapcar.submitStatus = true
          this.$store.dispatch('swapcars/swapcarSave', this.swapcar)
        }
      })
    },
  },
}
</script>
