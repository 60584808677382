<template>
  <b-form-group
    label="Ekspertiz Talep Eden"
    label-for="id_com_user_request"
  >
    <v-select
      id="id_com_user_request"
      v-model="swapcar.id_com_user_request"
      label="title"
      :reduce="user => user.id"
      :options="users"
      placeholder="Ekspertiz Talep Eden"
    />
  </b-form-group>
</template>
<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'RequestUsers',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    localize(this.locale)
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: ['com_user.id AS id', 'com_user.name AS title'],
        where: {
          'com_user.status': 1,
        },
      })
    },
  },
}
</script>

<style scoped>
</style>
