<template>
  <b-card title="Talep Bilgileri">
    <b-row>
      <b-col cols="12">
        <request-users />
      </b-col>
      <b-col cols="6">
        <swap-price />
      </b-col>
      <b-col cols="6">
        <request-price />
      </b-col>
      <b-col cols="6">
        <exper-notes />
      </b-col>
      <b-col cols="6">
        <tramer />
      </b-col>
      <b-col cols="12">
        <notes />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import RequestUsers from '@/views/Swapcars/forms/Request_users.vue'
import SwapPrice from '@/views/Swapcars/forms/Swap_price.vue'
import RequestPrice from '@/views/Swapcars/forms/Request_price.vue'
import ExperNotes from '@/views/Swapcars/forms/Exper_notes.vue'
import Tramer from '@/views/Swapcars/forms/Tramer.vue'
import Notes from '@/views/Swapcars/forms/Notes.vue'

export default {
  name: 'RequestDetail',
  components: {
    BCard,
    BRow,
    BCol,
    RequestUsers,
    SwapPrice,
    RequestPrice,
    ExperNotes,
    Tramer,
    Notes,
  },
}
</script>

<style scoped>

</style>
