<template>
  <b-form-group
    label="Eksper Notu"
    label-for="expertise"
  >
    <b-form-textarea
      id="expertise"
      v-model="swapcar.expertise_report"
      placeholder="Eksper Notu"
    />
  </b-form-group>
</template>
<script>
import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  name: 'ExperNotes',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
}
</script>
<style scoped>
</style>
