import { render, staticRenderFns } from "./RequestDetail.vue?vue&type=template&id=5a29e23b&scoped=true&"
import script from "./RequestDetail.vue?vue&type=script&lang=js&"
export * from "./RequestDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a29e23b",
  null
  
)

export default component.exports