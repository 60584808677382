<template>
  <b-form-group
    label="Ruhsat Sahibi"
    label-for="license_owner"
  >
    <b-form-input
      id="license_owner"
      v-model="swapcar.license_owner"
      placeholder="Ruhsat Sahibi"
    />
  </b-form-group>
</template>
<script>
import { localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'LicenseOwner',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
<style scoped>
</style>
