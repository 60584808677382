<template>
  <b-form-group
    label="Takas Bedeli"
    label-for="swap_price"
  >
    <validation-provider
      #default="{ errors }"
      name="Takas Bedeli"
      rules="required"
    >
      <cleave
        id="swap_price"
        v-model="swapcar.swap_price"
        class="form-control"
        :raw="true"
        :options="options.price"
        placeholder="Takas Bedeli"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'SwapPrice',
  components: {
    BFormGroup,
    Cleave,
    ValidationProvider,
  },
  data() {
    return {
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  created() {
    localize('tr')
  },
}
</script>

<style scoped></style>
