<template>
  <b-card>
    <b-row>
      <b-col>
        <b-card-title>Araç Bilgileri</b-card-title>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="swapcar.detailForm"
          name="check-button"
          switch
          inline
          @change="expertiseData"
        >
          Ekspertiz Formu
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <invoice-type />
      </b-col>
      <b-col>
        <brands />
      </b-col>
      <b-col>
        <models />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <hardware />
      </b-col>
      <b-col>
        <model-year />
      </b-col>
    </b-row>
    <b-row>
      <b-col><cc /></b-col>
      <b-col><hp /></b-col>
    </b-row>
    <b-row>
      <b-col>
        <colors />
      </b-col>
      <b-col><fuels /></b-col>
      <b-col><gears /></b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <chasis />
      </b-col>
      <b-col cols="6">
        <license-plate />
      </b-col>
      <b-col cols="12">
        <license-owner />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import InvoiceType from '@/views/Swapcars/forms/Invoice_type.vue'
import Brands from '@/views/Swapcars/forms/Brands.vue'
import Models from '@/views/Swapcars/forms/Models.vue'
import Hardware from '@/views/Swapcars/forms/Hardware.vue'
import ModelYear from '@/views/Swapcars/forms/Model_year.vue'
import Colors from '@/views/Swapcars/forms/Colors.vue'
import Fuels from '@/views/Swapcars/forms/Fuels.vue'
import Gears from '@/views/Swapcars/forms/Gears.vue'
import Cc from '@/views/Swapcars/forms/Cc.vue'
import Hp from '@/views/Swapcars/forms/Hp.vue'
import LicensePlate from '@/views/Swapcars/forms/License_plate.vue'
import Chasis from '@/views/Swapcars/forms/Chasis.vue'
import LicenseOwner from '@/views/Swapcars/forms/License_owner.vue'

export default {
  name: 'Public',
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormCheckbox,
    InvoiceType,
    Brands,
    Models,
    Hardware,
    ModelYear,
    Colors,
    Fuels,
    Gears,
    Cc,
    Hp,
    LicensePlate,
    Chasis,
    LicenseOwner,
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  methods: {
    expertiseData() {
      if (this.swapcar.detailForm) {
        if (this.swapcar.expertise.length === 0) {
          this.swapcar.expertise = ['1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1']
        }
      } else {
        this.swapcar.expertise = []
      }
    },
  },
}
</script>

<style scoped>

</style>
