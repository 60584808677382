<template>
  <b-form-group
    :label="label"
    :label-for="'expertise_' + itemKey"
  >
    <v-select
      :id="'expertise_' + itemKey"
      v-model="swapcar.expertise[itemKey]"
      label="title"
      :reduce="option => option.id"
      :options="options"
      :placeholder="label"
    />
  </b-form-group>
</template>
<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'ExpertiseSelect',
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    itemKey: {
      type: Number,
    },
  },
  data() {
    return {
      locale: 'tr',
      options: [
        {
          id: '1',
          title: 'Orijinal',
        },
        {
          id: '2',
          title: 'Boyanmış',
        },
        {
          id: '3',
          title: 'Değişmiş',
        },
      ],
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  created() {
    localize('tr')
  },
}
</script>

<style scoped>

</style>
